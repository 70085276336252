import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import arrowDownPath from "../../assets/images/png/arrow_down.png";
import arrowUpPath from "../../assets/images/png/arrow_up.png";
import { useTranslation } from "react-i18next";
import logoutIcon from "../../assets/images/topbar/logout.png";
import profileIcon from "../../assets/images/topbar/profile.png";

import { useIa } from "../../common/contexts/iaContext";
import { BLUE, WHITE } from "../../constants/cts_colors";

const IATopbar = () => {
  const { iaUser, iaUserLogout, iaUserForgot } = useIa();
  const [isOptionsMenuOpened, _setIsOptionsMenuOpened] =
    useState<boolean>(false);
  const refOpenOptionsMenu = useRef<HTMLDivElement>(null);
  const refOptionsMenu = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const menuOptionElementClass = "topbar-profile-menu-option";

  // DETECT WHEN THE USER CLICKS OUTSIDE THE OPTIONS POPUP COMPONENT
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleCloseMenu(event: Event) {
      const optionsMenu = refOptionsMenu.current;
      const openOptionsMenu = refOpenOptionsMenu.current;

      if (
        isOptionsMenuOpened &&
        optionsMenu &&
        !optionsMenu.contains(event.target as Node) && // clicked outside the menu
        openOptionsMenu &&
        !openOptionsMenu.contains(event.target as Node) // clicked outside the open menu btn
      ) {
        _setIsOptionsMenuOpened(false);
      }

      // close menu if an option was selected
      const menuOption = (event.target as HTMLElement).closest(
        `.${menuOptionElementClass}`
      );
      if (menuOption) {
        _setIsOptionsMenuOpened(false);
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleCloseMenu);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleCloseMenu);
    };
  }, [refOptionsMenu.current, refOpenOptionsMenu.current, isOptionsMenuOpened]);

  return (
    <Container>
      {iaUser && (
        <Profile>
          <OpenOptions
            onClick={() => _setIsOptionsMenuOpened(true)}
            ref={refOpenOptionsMenu}
          >
            <Name>{iaUser.email}</Name>
            <ArrowIcon
              src={isOptionsMenuOpened ? arrowUpPath : arrowDownPath}
              alt="arrow"
            />
          </OpenOptions>
          {isOptionsMenuOpened && (
            <OptionsMenu ref={refOptionsMenu}>
              <OptionButton
                onClick={() => iaUserForgot()}
                className={menuOptionElementClass}
              >
                <OptionIcon src={profileIcon} alt="logout" />
                <OptionText>Supprimer mes données</OptionText>
              </OptionButton>
              {/* logout */}
              <OptionButton
                onClick={() => iaUserLogout()}
                className={menuOptionElementClass}
              >
                <OptionIcon src={logoutIcon} alt="logout" />
                <OptionText>{t("profile.logout", { ns: "topbar" })}</OptionText>
              </OptionButton>
            </OptionsMenu>
          )}
        </Profile>
      )}
    </Container>
  );
};

export default IATopbar;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Container = styled.div`
  width: 100%;
  background-color: ${WHITE};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 40px;
`;

const Profile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const Name = styled.span`
  display: block;
  font-size: 16px;
  font-weight: bold;
`;

const OpenOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const ArrowIcon = styled.img`
  display: block;
  width: 16px;
  height: 16px;

  // prevent dragging
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const OptionsMenu = styled.div`
  position: absolute;
  top: 50px;
  right: 0px;
  background-color: ${WHITE};
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 6px 10px 20px 0px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  z-index: 1;
`;

const OptionButton = styled.button`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  padding: 8px 38px 8px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 250ms, color 250ms;

  // prevent dragging
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &:hover {
    background-color: ${BLUE}11;
  }
`;

const OptionIcon = styled.img`
  display: block;
  width: 40px;
  height: 40px;

  // prevent dragging
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const OptionText = styled.span`
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
`;
