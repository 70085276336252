import React, { useState, createContext, useCallback, useEffect } from "react";
import { IIaContext, IIaModel, IIaProfil, IIaUser } from "../../interfaces/ia";
import {
  completeMyProfilApi,
  converseApi,
  getQuestionApi,
  onAISignInApi,
  onAskMeProfilApi,
  onLoadProfilsApi,
  onModelListApi,
  iaUserForgotApi,
  setRemarqueApi,
} from "../../api/ia.api";

const IaContext = createContext(null);
const TOKEN_IA_USER_AUTHENTICATED = "token-ia-user-authenticated";

export function IaProvider(props: any) {
  const [iaUser, setIaUser] = useState<IIaUser | null>(null);
  const [modelList, setModelList] = useState<IIaModel[]>([]);
  const [profilList, setProfilList] = useState<IIaProfil[]>([]);
  const [modelSelected, setModelSelected] = useState<IIaModel | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnonyme, setIsAnonyme] = useState(false);

  const onAiSignIn = useCallback(async (email: string) => {
    setIsLoading(true);
    const user = await onAISignInApi(email);
    setIaUser(user);
    setIsLoading(false);
    return user;
  }, []);

  const onModelList = useCallback(async () => {
    setIsLoading(true);
    const list = await onModelListApi();
    setModelList(list);
    setIsLoading(false);
    return list;
  }, []);

  const onLoadProfils = useCallback(async (userId: number, modelId: number) => {
    setIsLoading(true);
    const list = await onLoadProfilsApi(userId, modelId);
    setProfilList(list);
    setIsLoading(false);
    return list;
  }, []);

  const onAskMeProfil = useCallback(async () => {
    setIsLoading(true);
    let response = null;
    if (iaUser) {
      response = await onAskMeProfilApi(iaUser.id);
    }
    setIsLoading(false);
    return response;
  }, [iaUser, modelSelected]);

  const completeMyProfil = useCallback(
    async (question: string, message: string) => {
      setIsLoading(true);
      let response = null;
      if (iaUser) {
        response = await completeMyProfilApi(iaUser.id, question, message);
      }
      setIsLoading(false);
      return response;
    },
    [iaUser]
  );

  const converse = useCallback(
    async (question: string, message: string) => {
      setIsLoading(true);
      let response = null;
      if (iaUser) {
        response = await converseApi(iaUser.id, question, message, isAnonyme);
      }
      setIsLoading(false);
      return response;
    },
    [iaUser, isAnonyme]
  );

  const getQuestion = useCallback(async () => {
    setIsLoading(true);
    let response = null;
    if (iaUser) {
      response = await getQuestionApi(iaUser.id, isAnonyme);
    }
    setIsLoading(false);
    return response;
  }, [iaUser, isAnonyme]);

  const iaUserLogout = () => {
    localStorage.removeItem(TOKEN_IA_USER_AUTHENTICATED);
    setIaUser(null);
  };

  const iaUserForgot = async () => {
    if (iaUser && confirm("Êtes-vous sur de voulez supprimer votre profil ?")) {
      await iaUserForgotApi(iaUser.id);
    }
    window.location.reload();
  };

  const setRemarque = useCallback(
    async (remarque: string) => {
      setIsLoading(true);
      let response = null;
      if (iaUser) {
        response = await setRemarqueApi(remarque, iaUser.id, isAnonyme);
      }
      setIsLoading(false);
      return response;
    },
    [iaUser, isAnonyme]
  );

  useEffect(() => {
    if (iaUser) {
      localStorage.setItem(TOKEN_IA_USER_AUTHENTICATED, JSON.stringify(iaUser));
      onModelList();
    }
  }, [iaUser]);

  useEffect(() => {
    setProfilList([]);

    if (iaUser && modelSelected) {
      onLoadProfils(iaUser.id, modelSelected.id);
    }
  }, [iaUser, modelSelected]);

  useEffect(() => {
    if (localStorage.getItem(TOKEN_IA_USER_AUTHENTICATED)) {
      setIaUser(
        JSON.parse(localStorage.getItem(TOKEN_IA_USER_AUTHENTICATED) || "")
      );
    }
  }, []);

  return (
    <IaContext.Provider
      {...props}
      value={{
        isLoading,
        modelSelected,
        iaUser,
        modelList,
        profilList,
        isAnonyme,
        setIsAnonyme,
        setModelSelected,
        onAiSignIn,
        onAskMeProfil,
        completeMyProfil,
        converse,
        getQuestion,
        iaUserLogout,
        iaUserForgot,
        setRemarque,
      }}
    />
  );
}

export const useIa = (): IIaContext => {
  const context = React.useContext(IaContext);
  if (!context) throw new Error("useAi must be used in IaProvider");

  return context;
};
