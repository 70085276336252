import { IIaModel, IIaProfil, IIaUser } from "../interfaces/ia";
import { httpError, instanceAxios } from "../utils/axios-api";

export const onAISignInApi = async (email: string): Promise<IIaUser | null> => {
  return instanceAxios
    .post(`/ia/sign-in`, { email })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};

export const onModelListApi = async (): Promise<IIaModel[]> => {
  return instanceAxios
    .get(`/ia/model-list`)
    .then((response) => (response.data ? response.data.data : []))
    .catch(httpError);
};

export const onLoadProfilsApi = async (
  userId: number,
  modelId: number
): Promise<IIaProfil[]> => {
  return instanceAxios
    .post(`/ia/get-profil`, { userId, modelId })
    .then((response) => (response.data ? response.data.data : []))
    .catch(httpError);
};

export const onAskMeProfilApi = async (
  userId: number
): Promise<string | null> => {
  return instanceAxios
    .post(`/ia/ask-me-profil`, { userId })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};

export const completeMyProfilApi = async (
  userId: number,
  question: string,
  message: string
): Promise<string | null> => {
  return instanceAxios
    .post(`/ia/complete-my-profil`, {
      userId,
      question,
      userResponse: message,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};

export const converseApi = async (
  userId: number,
  question: string,
  message: string,
  isAnonyme: boolean
): Promise<string | null> => {
  return instanceAxios
    .post(`/ia/converse`, {
      userId,
      userResponse: message,
      question,
      isAnonyme,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};

export const getQuestionApi = async (
  userId: number,
  isAnonyme: boolean
): Promise<string | null> => {
  return instanceAxios
    .post(`/ia/get-question`, { userId, isAnonyme })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};

export const iaUserForgotApi = async (userId: number) => {
  return instanceAxios
    .post(`/ia/user-forgot-infos`, { userId })
    .catch(httpError);
};

export const setRemarqueApi = async (
  remarque: string,
  userId: number,
  isAnonyme: boolean
) => {
  return instanceAxios
    .post(`/ia/set-remarque`, { userRemarque: remarque, userId, isAnonyme })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};
