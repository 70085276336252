import styled from "styled-components";
import { useIa } from "../../common/contexts/iaContext";
import IaTrainingNewUserComponent from "./ia-training-new-user";
import { useEffect, useState } from "react";
import IaTrainingConversationComponent from "./ia-training-conversation";
import IAWrapperConnected from "./ia-wrapper-conected";
import Input from "../../components/form/Input";
import Button from "../../components/Button";
import { BLUE, LIGHT_GREY, WHITE } from "../../constants/cts_colors";

const IaTrainingPage = () => {
  const {
    iaUser,
    onAskMeProfil,
    isLoading,
    completeMyProfil,
    isAnonyme,
    setIsAnonyme,
  } = useIa();
  const [requestProfil, setRequestProfil] = useState("");
  const [responseToProfilIA, setResponseToProfilIA] = useState("");

  const onRequestProfil = async () => {
    if (iaUser) {
      const profil = await onAskMeProfil();
      setRequestProfil(profil || "");
    }
  };

  const onSentProfilResponse = async () => {
    if (!responseToProfilIA) {
      alert("Vous devez saisir une réponse !");
      return;
    }

    const reponse = await completeMyProfil(requestProfil, responseToProfilIA);
    setResponseToProfilIA("");
    setRequestProfil(reponse || "");
  };

  useEffect(() => {
    if (iaUser) {
      onRequestProfil();
    }
  }, [iaUser]);

  if (!isAnonyme && !iaUser) {
    return (
      <>
        <IaTrainingNewUserComponent />
      </>
    );
  }

  return (
    <IAWrapperConnected>
      <Header>
        {isLoading && (
          <p>
            <b>Chargement...</b>
          </p>
        )}
      </Header>

      <Container>
        <RGPDRow>
          <SwitchButton>
            <input
              type="checkbox"
              checked={isAnonyme}
              onChange={() => {
                setIsAnonyme(!isAnonyme);
              }}
            />
            <span className="slider"></span>
          </SwitchButton>
          <p>Répondre sans personnalisation</p>
        </RGPDRow>

        {requestProfil && (
          <>
            <p>
              <b>
                Avant de commencer, pour affiner l'entrainement, nous avons
                besoin d'en savoir un peu plus sur toi.
              </b>
            </p>
            <p>{requestProfil}</p>
            <Input
              placeholder="Votre réponse"
              onChange={(e) => setResponseToProfilIA(e.target.value)}
              value={responseToProfilIA}
              onPressEnter={() => onSentProfilResponse()}
            />
            <Button text="Envoyer" onClick={() => onSentProfilResponse()} />
          </>
        )}

        {!requestProfil && <IaTrainingConversationComponent />}
      </Container>
    </IAWrapperConnected>
  );
};

export default IaTrainingPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Header = styled.div`
  padding: 16px;
`;

const Container = styled.div`
  padding: 16px;

  h2 {
    font-size: 24px;
    font-weight: bold;
  }

  p {
    margin-bottom: 16px;
  }
`;

const SwitchButton = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: ${BLUE};
    }

    &:focus + .slider {
      box-shadow: 0 0 1px ${BLUE};
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(24px);
      -ms-transform: translateX(24px);
      transform: translateX(24px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${LIGHT_GREY};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: ${WHITE};
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
`;

const RGPDRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  > p {
    margin-left: 16px;
    margin-bottom: 0;
  }
`;
