import styled from "styled-components";
import { useIa } from "../../common/contexts/iaContext";
import Button from "../../components/Button";
import Input from "../../components/form/Input";
import { useState } from "react";
import Markdown from "markdown-to-jsx";

const IaRemarquesConversationComponent = () => {
  const { setRemarque, isLoading } = useIa();
  const [conversationList, setConversationList] = useState<
    { message: string; fromIA: boolean }[]
  >([]);
  const [responseToIA, setResponseToIA] = useState("");

  const onSentRemarque = async () => {
    if (!responseToIA) {
      alert("Vous devez saisir une remarque !");
      return;
    }

    const reponse = await setRemarque(responseToIA);
    if (reponse) {
      setConversationList([
        ...conversationList,
        { message: `Votre remarque : ${responseToIA}`, fromIA: false },
        { message: reponse, fromIA: true },
      ]);
      setResponseToIA("");
    }
  };

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <Wrapper>
      <h1>
        <b>Soumets ici une remarque sexiste pour que je t'aide à y répondre</b>
      </h1>
      {conversationList.length !== 0 && (
        <ConversationZone>
          {conversationList.map((cs, index) => (
            <div key={index}>
              {cs.fromIA && (
                <p>
                  <b>Coach Respect</b>
                </p>
              )}
              {!cs.fromIA && (
                <p className="text-align-right">
                  <b>Ton message</b>
                </p>
              )}
              {(cs.message || "").split("\n").map((m, i) => (
                <MarkdownRow
                  key={i}
                  className={cs.fromIA ? "" : "text-align-right"}
                >
                  <Markdown className={cs.fromIA ? "" : "text-align-right"}>
                    {m}
                  </Markdown>
                </MarkdownRow>
              ))}
            </div>
          ))}
        </ConversationZone>
      )}
      <Input
        placeholder="Votre remarque"
        onChange={(e) => setResponseToIA(e.target.value)}
        value={responseToIA}
        onPressEnter={() => onSentRemarque()}
      />
      <Button text="Aides-moi à répondre" onClick={onSentRemarque} />
    </Wrapper>
  );
};

export default IaRemarquesConversationComponent;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  padding: 16px;
`;

const ConversationZone = styled.div`
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid black;
  margin-bottom: 32px;

  > div:first-child {
    margin-bottom: 16px;
  }
`;

const MarkdownRow = styled.div`
  margin-bottom: 8px;
`;
