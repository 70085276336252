import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import little_logo from "../../assets/images/logo/logo-les-cles.png";
import PATH from "../../constants/cts_routes";
import {
  BLACK,
  BLUE,
  LIGHT_BLUE,
  LIGHT_GREY,
  WHITE,
} from "../../constants/cts_colors";
import { TbSos } from "react-icons/tb";
import { GiWeightLiftingUp } from "react-icons/gi";

const IAHeader = () => {
  const [actualCategory, _setActualCategory] = useState<string>(
    window.location.pathname.split("/")[1]
  );

  useEffect(() => {
    _setActualCategory(window.location.pathname.split("/")[1]);
    // console.log(user);
  }, [window.location.pathname.split("/")[1]]);

  return (
    <Wrapper>
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* <Link to={getPageUrl(PATH.home)}> */}
          <LogoContainer>
            <img src={little_logo} alt="Logo Les Clefs" />
          </LogoContainer>
          {/* </Link> */}
          <ItemContainer
            to={"/" + PATH.iatraining}
            style={{
              backgroundColor: actualCategory === "entrainement" ? BLUE : WHITE,
            }}
          >
            <GiWeightLiftingUp
              size={24}
              color={actualCategory === "entrainement" ? WHITE : BLACK}
            />
            <p
              style={{
                color: actualCategory === "entrainement" ? WHITE : BLACK,
              }}
            >
              Commencer un entrainement
            </p>
          </ItemContainer>
          <ItemContainer
            to={"/" + PATH.iaremarques}
            style={{
              backgroundColor: actualCategory === "remarques" ? BLUE : WHITE,
            }}
          >
            <TbSos
              size={24}
              color={actualCategory === "remarques" ? WHITE : BLACK}
            />
            <p
              style={{
                color: actualCategory === "remarques" ? WHITE : BLACK,
              }}
            >
              Soumettre une remarque
            </p>
          </ItemContainer>
        </div>
      </div>
    </Wrapper>
  );
};

export default IAHeader;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.nav`
  width: 246px;

  > div {
    width: 246px;
    height: 100vh;
    background-color: ${WHITE};
    color: ${BLUE};
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid ${LIGHT_GREY};
  }
`;

const LogoContainer = styled.div`
  margin: 40px 16px;

  img {
    height: 33px;
  }
`;

const ItemContainer = styled(Link)`
  padding: 16px;
  margin: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;

  :hover {
    background-color: ${LIGHT_BLUE};
  }

  p {
    margin-left: 16px;
    position: relative;
    top: 2px;
    color: ${BLACK};
  }
`;
