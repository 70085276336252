import { ReactNode } from "react";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import { useWindowSize } from "../../hooks/windowsSize";
import { useIa } from "../../common/contexts/iaContext";
import { MOBILE_WIDTH } from "../../constants/cts_sizes";
import { BLACK, WHITE } from "../../constants/cts_colors";
import IAHeader from "./ia-header";
import IATopbarMobile from "./ia-topbar-mobile";
import IATopbar from "./ia-topbar";

/**
 * This component is used to encompass the Header, Footer and given children together
 */

const IAWrapperConnected = ({ children }: { children: ReactNode }) => {
  const { iaUser } = useIa();
  const [width] = useWindowSize();

  return (
    <Wrapper>
      {iaUser && MOBILE_WIDTH < width && (
        <div>
          <IAHeader />
        </div>
      )}
      <Container>
        {iaUser && MOBILE_WIDTH < width && <IATopbar />}
        {iaUser && MOBILE_WIDTH >= width && <IATopbarMobile />}
        <Children>{children}</Children>
        {/* {audio && <HeaderAudioPlayer />} */}
      </Container>
    </Wrapper>
  );
};

export default IAWrapperConnected;

IAWrapperConnected.propTypes = {
  /** Children to render*/
  children: PropTypes.any.isRequired,
};

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  display: flex;
  flex: 1 auto;
`;

const Container = styled.div`
  flex: 1 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Children = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
`;

const SelectLanguage = styled.select`
  position: fixed;
  cursor: pointer;
  bottom: 10px;
  right: 10px;
  background: ${WHITE};
  color: ${BLACK};
  border-radius: 50%;
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  appearance: none;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
    0px 5px 15px 0px rgba(0, 0, 0, 0.12);
`;
