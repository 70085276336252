import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import logoutIcon from "../../assets/images/topbar/logout.png";
import { Link } from "react-router-dom";
import little_logo from "../../assets/images/logo/logo-les-cles.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdClose } from "react-icons/md";
import PATH from "../../constants/cts_routes";
import {
  BLACK,
  BLUE,
  CLEAR_BLUE,
  LIGHT_BLUE,
  WHITE,
} from "../../constants/cts_colors";
import { useIa } from "../../common/contexts/iaContext";
import profileIcon from "../../assets/images/topbar/profile.png";
import { TbSos } from "react-icons/tb";
import { GiWeightLiftingUp } from "react-icons/gi";

const IATopbarMobile = () => {
  const [isOptionsMenuOpened, _setIsOptionsMenuOpened] =
    useState<boolean>(false);
  const refOpenOptionsMenu = useRef<HTMLDivElement>(null);
  const refOptionsMenu = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const menuOptionElementClass = "topbar-profile-menu-option";
  const [showMenu, setShowMenu] = useState(false);
  const { iaUser, iaUserLogout, iaUserForgot } = useIa();

  const [actualCategory, _setActualCategory] = useState<string>(
    window.location.pathname.split("/")[1]
  );

  useEffect(() => {
    _setActualCategory(window.location.pathname.split("/")[1]);
    // console.log(user);
  }, [window.location.pathname.split("/")[1]]);

  // DETECT WHEN THE USER CLICKS OUTSIDE THE OPTIONS POPUP COMPONENT
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleCloseMenu(event: Event) {
      const optionsMenu = refOptionsMenu.current;
      const openOptionsMenu = refOpenOptionsMenu.current;

      if (
        isOptionsMenuOpened &&
        optionsMenu &&
        !optionsMenu.contains(event.target as Node) && // clicked outside the menu
        openOptionsMenu &&
        !openOptionsMenu.contains(event.target as Node) // clicked outside the open menu btn
      ) {
        _setIsOptionsMenuOpened(false);
      }

      // close menu if an option was selected
      const menuOption = (event.target as HTMLElement).closest(
        `.${menuOptionElementClass}`
      );
      if (menuOption) {
        _setIsOptionsMenuOpened(false);
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleCloseMenu);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleCloseMenu);
    };
  }, [refOptionsMenu.current, refOpenOptionsMenu.current, isOptionsMenuOpened]);

  return (
    <Container>
      <Header>
        <LogoContainer>
          <img src={little_logo} alt="Logo Les Clefs" />
        </LogoContainer>
        <ActionButton onClick={() => setShowMenu(!showMenu)}>
          {!showMenu && <RxHamburgerMenu size={24} color={BLUE} />}
          {showMenu && <MdClose size={24} color={BLUE} />}
        </ActionButton>
      </Header>
      {showMenu && (
        <DropdownHeader>
          <WhitePanel>
            <ItemContainer
              to={"/" + PATH.iatraining}
              onClick={() => setShowMenu(false)}
              style={{
                backgroundColor:
                  actualCategory === "entrainement" ? BLUE : WHITE,
              }}
            >
              <GiWeightLiftingUp
                size={24}
                color={actualCategory === "entrainement" ? WHITE : BLACK}
              />
              <p
                style={{
                  color: actualCategory === "entrainement" ? WHITE : BLACK,
                }}
              >
                Commencer un entrainement
              </p>
            </ItemContainer>
            <ItemContainer
              to={"/" + PATH.iaremarques}
              onClick={() => setShowMenu(false)}
              style={{
                backgroundColor: actualCategory === "remarques" ? BLUE : WHITE,
              }}
            >
              <TbSos
                size={24}
                color={actualCategory === "remarques" ? WHITE : BLACK}
              />
              <p
                style={{
                  color: actualCategory === "remarques" ? WHITE : BLACK,
                }}
              >
                Soumettre une remarque
              </p>
            </ItemContainer>
          </WhitePanel>
          {iaUser && (
            <UserPanel>
              <ItemUser>
                <Name>{iaUser.email}</Name>
              </ItemUser>

              <ItemUser
                onClick={() => {
                  iaUserForgot();
                }}
              >
                <IconLink>
                  <OptionIcon src={profileIcon} alt="logout" />
                </IconLink>
                <Name>Supprimer mes données</Name>
              </ItemUser>
              <ItemUser
                onClick={() => {
                  iaUserLogout();
                }}
              >
                <IconLink>
                  <OptionIcon src={logoutIcon} alt="logout" />
                </IconLink>
                <Name>{t("profile.logout", { ns: "topbar" })}</Name>
              </ItemUser>
            </UserPanel>
          )}
        </DropdownHeader>
      )}
    </Container>
  );
};

export default IATopbarMobile;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Container = styled.div`
  width: 100%;
  background-color: ${WHITE};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px 16px 24px;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  img {
    height: 24px;
  }
`;

const ActionButton = styled.div`
  background-color: ${CLEAR_BLUE};
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropdownHeader = styled.div`
  position: fixed;
  height: calc(100vh - 72px);
  left: 0;
  right: 0;
  top: 72px;
  background-color: ${CLEAR_BLUE};
  z-index: 1000;
  overflow: auto;
`;

const ItemContainer = styled(Link)`
  padding: 16px;
  margin: 0 16px 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;

  :hover {
    background-color: ${LIGHT_BLUE};
  }

  p {
    margin-left: 16px;
    position: relative;
    top: 2px;
    color: ${BLACK};
  }
`;

const WhitePanel = styled.div`
  background-color: ${WHITE};
  padding: 16px 0;
`;

const UserPanel = styled.div`
  padding: 32px 24px;
`;

const Name = styled.span`
  display: block;
  font-size: 16px;
  font-weight: bold;
`;

const ItemUser = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  > div:first-child {
    margin-right: 8px;
  }
`;

const IconLink = styled.div`
  background-color: ${WHITE};
  border-radius: 40px;
  width: 40px;
  height: 40px;
`;

const OptionIcon = styled.img`
  display: block;
  width: 40px;
  height: 40px;

  // prevent dragging
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;
