import styled from "styled-components";
import { useIa } from "../../common/contexts/iaContext";
import IaTrainingNewUserComponent from "./ia-training-new-user";
import { useEffect, useState } from "react";
import IAWrapperConnected from "./ia-wrapper-conected";
import Input from "../../components/form/Input";
import Button from "../../components/Button";
import IaRemarquesConversationComponent from "./ia-remarques-conversation";

const IaTRemarquesPage = () => {
  const { iaUser, onAskMeProfil, isLoading, completeMyProfil } = useIa();
  const [requestProfil, setRequestProfil] = useState("");
  const [responseToProfilIA, setResponseToProfilIA] = useState("");

  const onRequestProfil = async () => {
    if (iaUser) {
      const profil = await onAskMeProfil();
      setRequestProfil(profil || "");
    }
  };

  const onSentProfilResponse = async () => {
    if (!responseToProfilIA) {
      alert("Vous devez saisir une réponse !");
      return;
    }

    const reponse = await completeMyProfil(requestProfil, responseToProfilIA);
    setResponseToProfilIA("");
    setRequestProfil(reponse || "");
  };

  useEffect(() => {
    if (iaUser) {
      onRequestProfil();
    }
  }, [iaUser]);

  if (!iaUser) {
    return <IaTrainingNewUserComponent />;
  }

  return (
    <IAWrapperConnected>
      <Header>
        {isLoading && (
          <p>
            <b>Chargement...</b>
          </p>
        )}
      </Header>

      <Container>
        {requestProfil && (
          <>
            <p>
              <b>
                Afin d'affiner les réponses, nous avons besoin en savoir un peu
                plus sur vous.
              </b>
            </p>
            <p>{requestProfil}</p>
            <Input
              placeholder="Votre réponse"
              onChange={(e) => setResponseToProfilIA(e.target.value)}
              value={responseToProfilIA}
            />
            <Button text="Envoyer" onClick={() => onSentProfilResponse()} />
          </>
        )}

        {!requestProfil && <IaRemarquesConversationComponent />}
      </Container>
    </IAWrapperConnected>
  );
};

export default IaTRemarquesPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Header = styled.div`
  padding: 16px;
`;

const Container = styled.div`
  padding: 16px;

  h2 {
    font-size: 24px;
    font-weight: bold;
  }

  p {
    margin-bottom: 16px;
  }
`;
